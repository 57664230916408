import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>
            <span className="icon fa-spotify">&nbsp;</span>
            Spotify
          </h2>
          <p>Playlists</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Sessions West Artists Playlist</h3>
          <iframe
            src="https://open.spotify.com/embed/playlist/6Tl6ZXtAfhMCGSZ2SXxZZd"
            width="100%"
            height="800"
          ></iframe>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
